import ken1 from "../images/ken1.jpg";
import ken3 from "../images/ken3.jpg";
import ken4 from "../images/ken4.jpg";
import ken5 from "../images/ken5.jpg";

import ken11 from "../images/ken11.jpg";
import ken12 from "../images/ken12.jpg";




export const slidesK = [
  {
    src: ken1,
    caption: ""
  },
 
  {
    src: ken3,
    caption: "B3"
  },
  {
    src: ken4,
    caption: "B4"
  },
  {
    src: ken5,
    caption: "B5"
  },
 
  {
    src: ken11,
    caption: "B11"
  }
  ,
  {
    src: ken12,
    caption: "B12"
  }
  
  
]; // Removed the trailing comma here