import box1 from "../images/box1.jpg";
import box2 from "../images/box2.jpg";
import box4 from "../images/box4.jpg";
import box5 from "../images/box5.jpg";
import box6 from "../images/box6.jpg";
import box7 from "../images/box7.jpg";
import box8 from "../images/box8.jpg";
import box9 from "../images/box9.jpg";
import boxG1 from "../images/boxG1.png";
import boxG3 from "../images/boxG3.png";




export const slides = [
  {
    src: box1,
    caption: "B1",
  },

  {
    src: box2,
    caption: "B2",
  },



  {src: box4, caption: "B4"},
  {src: box5, caption: "B5"},
  {src: box6, caption: "B6"},
  {src: box7, caption: "B7"},
  {src: box8, caption: "B8"},
  {src: box9, caption: "B9"},
  {src: boxG1, caption: "Group"},
  {src: boxG3, caption: "Boxing"},


];
